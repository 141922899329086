import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
// Landscape-Atherton-Tablelands-Upper-Barron-Road.jpg

export const query = graphql`
  query {
    landscape: file(
      relativePath: {
        eq: "Landscape-Atherton-Tablelands-Upper-Barron-Road.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2500, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Contact" />
    <section className="hero is-medium is-white">
      <div className="hero-body">
        <div className="container">
          <div className="content has-text-centered">
            <div className="soc">
              <div className="columns">
                <div className="column">
                  <div className="content">
                    <h1>details</h1>
                    <hr />

                    <p>
                      phone: <a href="tel:07 4096 5884">07 4096 5884</a>
                    </p>

                    <p>
                      mobile: <a href="tel:0418 980 644">0418 980 644</a>
                    </p>
                    <p>
                      email:{" "}
                      <a href="mailto:contact@tablelandphotography.com.au">
                        contact@tablelandphotography.com.au
                      </a>
                    </p>
                    <p>studio: 11 Kidd Close, Malanda, QLD 4885</p>
                    <p>ABN: 93 927 563 562</p>
                    <p>
                      facebook:{" "}
                      <a href="https://www.facebook.com/AthertonTablelandPhotography">
                        /AthertonTablelandPhotography
                      </a>
                    </p>
                  </div>
                </div>
                <div className="column">
                  <iframe
                    title="address"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3808.483189727813!2d145.63078621544798!3d-17.34046726749395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6978a3335c9d6cbb%3A0xb6e77624ceda45f0!2sAtherton%20Tableland%20Photography!5e0!3m2!1sen!2sau!4v1571012113050!5m2!1sen!2sau"
                    frameborder="0"
                    allowfullscreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p />
      </div>
    </section>
    <section>
      <figure className="image image-border">
        <Img fluid={data.landscape.childImageSharp.fluid} alt="" />
      </figure>
    </section>
  </Layout>
)

export default IndexPage
